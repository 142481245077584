import './exhibition.scss';

import { graphql, Link, PageProps } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import * as React from 'react';

import { View } from '../components/view';
import { ExhibitionQuery } from '../generated/graphql';
import { DateUtils } from '../utils/date';
import { Routes } from '../utils/routes';

export interface ExhibitionViewProps extends PageProps<ExhibitionQuery> {}

export const ExhibitionView: React.FC<ExhibitionViewProps> = ({ data }) => {
  return (
    <View
      className="exhibition-view"
      hideFooterLine
      title={`${data?.prismicExhibition?.data?.name} (${DateUtils.dateStringToYears(data?.prismicExhibition?.data?.date)})`}
    >
      <RichText render={data?.prismicExhibition?.data?.content?.richText} />

      <Link to={Routes.home}>back</Link>
    </View>
  );
};

export default ExhibitionView;

export const pageQuery = graphql`
  query Exhibition($id: String!) {
    prismicExhibition(id: { eq: $id }) {
      id
      data {
        name
        date
        content {
          richText
        }
      }
    }
  }
`;
