import { format, isBefore } from 'date-fns';

export namespace DateUtils {
  export const getDayOfWeek = (dateTime: string) => format(new Date(dateTime), 'EEEE');
  export const getDayOfWeekAndDate = (dateTime: string) => format(new Date(dateTime), 'EEEE do');

  export const isPast = (dateTime: string) => isBefore(new Date(dateTime), new Date());

  export const getDateFormat = (date: string) => {
    return format(new Date(date), 'EEEE do MMMM');
  };

  export const getDateRangeFormat = (dateTime: string, time: number) => {
    const date = new Date(dateTime);
    const endDate = new Date(date).setMinutes(date.getMinutes() + time);

    return `${format(date, 'EEEE do MMMM HH:mm')} – ${format(endDate, 'HH:mm')}`;
  };

  export const getEndtime = (dateTime: string, time: number) => {
    const date = new Date(dateTime);
    return new Date(date).setMinutes(date.getMinutes() + time);
  };

  export const getTimeRange = (dateTime: string, time: number) => {
    const date = new Date(dateTime);
    const endDate = getEndtime(dateTime, time);

    return `${format(date, 'HH:mm')} – ${format(endDate, 'HH:mm')}`;
  };

  export const isFinished = (dateTime: string, time: number) => {
    const endDate = getEndtime(dateTime, time);

    return isBefore(endDate, new Date());
  };

  export const dateStringToUnix = (dateTime: string) => (dateTime ? new Date(dateTime).getTime() : undefined);
  export const unixToHour = (unix: number) => (unix ? parseInt(format(new Date(unix), 'HH'), 10) : undefined);
  export const dateStringToHours = (dateTime: string) => (dateTime ? parseInt(format(new Date(dateTime), 'HH'), 10) : undefined);
  export const dateStringToMinutes = (dateTime: string) => (dateTime ? parseInt(format(new Date(dateTime), 'mm'), 10) : undefined);
  export const dateStringToHoursAndMinutes = (dateTime: string) => {
    if (!dateTime) {
      return 0;
    }

    const hours = dateStringToHours(dateTime);
    const minutes = dateStringToMinutes(dateTime);

    if (typeof hours !== 'undefined' && typeof minutes !== 'undefined') {
      return hours + minutes / 60;
    }
  };

  export const dateStringToYears = (dateTime: string) => dateTime && parseInt(format(new Date(dateTime), 'yyyy'), 10);
}
